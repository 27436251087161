import styles from "../style";
import { services } from "../constatns";

const Services = () => (
  <section id="services" className={`${styles.section}`}>
    <div className="flex flex-col px-6 lg:px-0">
      <h1 className={`flex-1 ${styles.sectionTitle}`}>Services</h1>

      <ul className="list-none">
        {services.map((service, index) => (
          <li
            key={service.id}
            className={`text-[16px] font-light ${
              index < services.length - 1 ? "mb-3" : ""
            }`}
          >
            {service.title}
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default Services;
