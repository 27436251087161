import "./navbar.scss";
import { navLinks } from "../../../constatns";
import { logo, close, menu } from "../../../assets/";
import { useState } from "react";

import styles from "./../../../style";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisiable = () => {
    const scrolled = document.documentElement.scrollTop;
    scrolled > 300 ? setIsVisible(true) : setIsVisible(false);
  };

  window.addEventListener("scroll", toggleVisiable);

  return (
    <div className="h-[90vh] bg-slate-50 flex justify-center items-center">
      {/* MAIN NAVIGATION */}
      <ul className="relative nav flex flex-col items-center justify-center">
        {navLinks.map((nav, index) => (
          // <a key={nav.id} href={nav.link}>
          <li
            key={nav.id}
            className={`nav__link ${styles.navLink} ${
              index < navLinks.length ? "mb-4" : ""
            }`}
          >
            <Link to={nav.link != "/#portfolio"?nav.link: ""} className={nav.id}>
              {nav.text}
              {nav.id === "portfolio" ? (
              <>
              <i className="fa-solid fa-chevron-right ml-2"></i>
                <div className="dropDown text-center ">
                  <li className="m-3 deepDrop ">
                    <Link to={nav.link} >Events</Link>
                  </li>
                  <li className="m-3 deepDrop">
                    <Link to="/#Kits" >PR Kits</Link>
                  </li>
                  <li className="m-3 deepDrop">
                    <Link to={nav.link} >Influencers Campaigns</Link>
                  </li>
                  <li className="m-3 deepDrop">
                    {" "}
                    <Link to={nav.link} >Production</Link>
                  </li>
                </div>
              </>
            ) : (
              ""
            )}
            </Link>
          </li>
          // </a>
        ))}
      </ul>

      {/* TOGGLE NAV */}
      <div
        className={`${
          isVisible ? "" : "hidden"
        } nav-toggle p-3 absolute top-0 left-0 w-full`}
      >
        {/* DASH Circle LOGO */}
        <div
          className={`${
            isVisible ? "fixed" : "hidden"
          } flex items-center gap-4 z-10 ml-3 mt-3`}
        >
          {/* <img
            src={menu}
            alt="menu"
            className={` nav__btn w-[25px] h-[25px] cursor-pointer`}
          /> */}
          <img
            id="navToggleBtn"
            src={logo}
            alt="dash"
            className={` nav__btn w-[50px] h-[50px] cursor-pointer`}
            onClick={() => setToggle((prev) => !prev)}
          />
        </div>

        <div
          style={{
            animation: `${
              toggle ? "moveDown .5s ease" : "moveUp .5s ease-out"
            }`,
          }}
          className={`toggleMenu ${toggle ? "flex top-0" : "-top-full hidden"} 
          
          
          fixed left-0 w-full h-full nav-toggle z-40 bg-white p-8 m-0 flex-col`}
        >
          <img
            src={close}
            alt="close menu"
            className="z-10 w-[25px] h-[25px] cursor-pointer self-start"
            onClick={() => setToggle((prev) => !prev)}
          />
          <ul className="pt-24 mb-auto flex flex-col items-center justify-center">
            {navLinks.map((nav, index) => (
              // <a
              //   key={nav.id}
              //   href={nav.link}
              //   onClick={() => {
              //     setToggle(false);
              //   }}
              // >
              <li
                key={nav.id}
                onClick={() => setToggle(false)}
                className={`nav__link ${styles.navLink} ${
                  index < navLinks.length ? "mb-4" : ""
                }`}
              >
                <Link to={nav.link}>{nav.text}</Link>
              </li>
              // </a>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
