export const navLinks = [
  {
    id: "about",
    link: "/#about",
    text: "ABOUT",
  },
  {
    id: "team",
    link: "/#team",
    text: "TEAM",
  },
  {
    id: "whoarewe",
    link: "/#whoarewe",
    text: "WHO WE ARE",
  },
  {
    id: "services",
    link: "/#services",
    text: "SERVICES",
  },
  {
    id: "portfolio",
    link: "/#portfolio",
    text: "SAMPLE OF OUR WORK",
  },
  {
    id: "clients",
    link: "/#clients",
    text: "CLIENTS",
  },
  {
    id: "career",
    link: "/#career",
    text: "CAREERS",
  },
];

export const footerLinks = [
  {
    title: "Contact Us",
    links: [
      {
        id: "instgram",
        label: "Instagram",
        value: "https://www.instagram.com/dashmanagement_/?hl=en",
      },

      {
        id: "mail",
        label: "info@dashmanagement.me",
        value: "info@dashmanagement.me",
      },
    ],
  },
];

export const services = [
  {
    id: "service_1",
    title: "Brand Consulting & Strategy",
  },
  {
    id: "service_2",
    title: "Content Creation",
  },
  {
    id: "service_3",
    title: "Digital Strategy",
  },
  {
    id: "service_4",
    title: "Partnerships, Talents, Influencer & Celebrity",
  },
  {
    id: "service_5",
    title: "Full Event Management",
  },
  {
    id: "service_6",
    title: "Influencer Campaigns",
  },
  {
    id: "service_7",
    title: "PR Kits",
  },
  {
    id: "service_8",
    title: "Video Production",
  },
  {
    id: "service_9",
    title: "Social Media Content",
  },
];

export const stats = [
  {
    id: "stats_1",
    num: "150+",
    text: "Digital PR Campaigns",
  },
  {
    id: "stats_2",
    num: "50+",
    text: "Video Production Projects",
  },
  {
    id: "stats_3",
    num: "50+",
    text: "On Ground Activations",
  },
  {
    id: "stats_4",
    num: "15+",
    text: "Social Media Content Creations",
  },
  {
    id: "stats_5",
    num: "100+",
    text: "Clients",
  },
];

// export const featuredProtfolio = [
//   {
//     id: "protfolio_1",
//     title: "Prime Developments",
//     src: "/img/portfolios/PrimeDevelopments/600/1.jpg",
//     link: "/portfolio/prime-developments",
//   },
//   {
//     id: "protfolio_2",
//     title: "Prime Developments",
//     src: "/img/portfolios/PrimeDevelopments/600/1.jpg",
//     link: "/portfolio/prime-developments",
//   },
//   {
//     id: "protfolio_3",
//     title: "Prime Developments",
//     src: "/img/portfolios/PrimeDevelopments/600/1.jpg",
//     link: "/portfolio/prime-developments",
//   },
//   {
//     id: "protfolio_4",
//     title: "Prime Developments",
//     src: "/img/portfolios/PrimeDevelopments/600/1.jpg",
//     link: "/portfolio/prime-developments",
//   },
// ];

export const portfolios = [
  // ----------------------------------- //
  // Prime Developments
  {
    id: "prime-developments",
    clientName: "Prime Developments",
    title: "Prime Developments",
    projectType: "Launch Event",
    workScope: "Public Relations and Guest List Management",
    location: "JW Marriott Hotel",
    numImg: 22,
    coverImg: "/img/portfolios/PrimeDevelopments/600/1.jpg",
    images: [
      {
        src: "/img/portfolios/PrimeDevelopments/2000/1.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/2.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/3.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/4.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/5.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/6.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/7.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/8.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/9.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/10.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/11.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/12.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/13.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/14.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/15.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/16.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/17.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/18.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/19.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/20.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
      {
        src: "/img/portfolios/PrimeDevelopments/2000/21.jpg",
        loading: "lazy",
        alt: "Prime Developments",
      },
    ],
    videos: [
      {
        id: "1",
        link: "https://www.youtube.com/embed/V_by5uvNFUg",
      },
    ],
  },

  // ----------------------------------- //
  // Jotun Wonderwall Lux Launch Event
  {
    id: "wonderwall-lux-launch",
    clientName: "Jotun",
    projectType: "Wonderwall Lux Launch Event",
    workScope:
      "Event Management + PR + Video Production + Guest List Management",
    location: "Lexies – Dusit Thani Swan Lake Hotel",
    title: "Jotun",
    numImg: 11,
    coverImg: "/img/portfolios/Jotun/600/4.jpg",
    images: [
      {
        src: "/img/portfolios/Jotun/2000/1.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/2.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/3.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/4.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/5.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/6.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/7.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/8.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/9.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/10.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
      {
        src: "/img/portfolios/Jotun/2000/11.jpg",
        loading: "lazy",
        alt: "Wonderwall Lux Launch Event",
      },
    ],
    videos: [
      {
        id: "1",
        link: "https://www.youtube.com/embed/IRqMHOYCtRo",
      },
    ],
  },

  // ----------------------------------- //
  // Kin of Geek Launch Event
  {
    id: "kin-of-geek",
    clientName: "Kin of Geek",
    projectType: "Launch Event and Influencers Store Visits",
    workScope: "Event Management + PR + Video Production",
    location: "Kin of Geek Store",
    numImg: 8,
    coverImg: "/img/portfolios/KinOfGeek/600/1.jpg",
    title: "Kin of Geek",
    images: [
      {
        src: "/img/portfolios/KinOfGeek/2000/1.jpg",
        loading: "lazy",
        alt: "Kin of Geek Launch Event",
      },
      {
        src: "/img/portfolios/KinOfGeek/2000/2.jpg",
        loading: "lazy",
        alt: "Kin of Geek Launch Event",
      },
      {
        src: "/img/portfolios/KinOfGeek/2000/3.jpg",
        loading: "lazy",
        alt: "Kin of Geek Launch Event",
      },
      {
        src: "/img/portfolios/KinOfGeek/2000/4.jpg",
        loading: "lazy",
        alt: "Kin of Geek Launch Event",
      },
      {
        src: "/img/portfolios/KinOfGeek/2000/5.jpg",
        loading: "lazy",
        alt: "Kin of Geek Launch Event",
      },
      {
        src: "/img/portfolios/KinOfGeek/2000/6.jpg",
        loading: "lazy",
        alt: "Kin of Geek Launch Event",
      },
      {
        src: "/img/portfolios/KinOfGeek/2000/7.jpg",
        loading: "lazy",
        alt: "Kin of Geek Launch Event",
      },
      {
        src: "/img/portfolios/KinOfGeek/2000/8.jpg",
        loading: "lazy",
        alt: "Kin of Geek Launch Event",
      },
    ],
    videos: [
      {
        id: "1",
        link: "https://www.youtube.com/embed/-KOR4jZj1Ok",
      },
    ],
  },
  // ----------------------------------- //
  // Jones the Grocer
  {
    id: "jones-the-grocer",
    clientName: "Jones the Grocer",
    projectType: "1 Year Anniversary Activation",
    workScope: "Event Management + PR + Video Production",
    title: "Jones the Grocer",
    location: "Jones the Grocer Store at Zayed and Tagamoa",
    numImg: 8,
    coverImg: "/img/portfolios/JonestheGrocer/600/1.jpg",
    images: [
      {
        src: "/img/portfolios/JonestheGrocer/2000/1.jpg",
        loading: "lazy",
        alt: "Jones the Grocer",
      },
      {
        src: "/img/portfolios/JonestheGrocer/2000/2.jpg",
        loading: "lazy",
        alt: "Jones the Grocer",
      },
      {
        src: "/img/portfolios/JonestheGrocer/2000/3.jpg",
        loading: "lazy",
        alt: "Jones the Grocer",
      },
      {
        src: "/img/portfolios/JonestheGrocer/2000/4.jpg",
        loading: "lazy",
        alt: "Jones the Grocer",
      },
      {
        src: "/img/portfolios/JonestheGrocer/2000/5.jpg",
        loading: "lazy",
        alt: "Jones the Grocer",
      },
      {
        src: "/img/portfolios/JonestheGrocer/2000/6.jpg",
        loading: "lazy",
        alt: "Jones the Grocer",
      },
      {
        src: "/img/portfolios/JonestheGrocer/2000/7.jpg",
        loading: "lazy",
        alt: "Jones the Grocer",
      },
      {
        src: "/img/portfolios/JonestheGrocer/2000/8.jpg",
        loading: "lazy",
        alt: "Jones the Grocer",
      },
    ],
    videos: [
      {
        id: "1",
        link: "https://www.youtube.com/embed/Bm90MIzVXYM",
      },
      {
        id: "2",
        link: "https://www.youtube.com/embed/pwvnP9PHSLI",
      },
    ],
  },

  // // ----------------------------------- //
  // // Bio Oil
  {
    id: "bio-oil",
    clientName: "Bio Oil",
    projectType: "Bio Oil Launch in Egypt Event",
    title: "Bio Oil",
    workScope:
      "Public Relations and Guest List Management + PR Kits Production",
    location: "Royal Maxim Palace Kempinski Hotel",
    numImg: 10,
    coverImg: "/img/portfolios/BioOil/600/9.jpg",
    images: [
      {
        src: "/img/portfolios/BioOil/2000/1.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/2.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/3.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/4.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/5.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/6.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/7.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/8.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/9.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
      {
        src: "/img/portfolios/BioOil/2000/10.jpg",
        loading: "lazy",
        alt: "Bio Oil",
      },
    ],
    videos: [
      {
        id: "1",
        link: "https://www.youtube.com/embed/SKla_JMUlUM",
      },
    ],
  },

  // // ----------------------------------- //
  // // Infinity
  {
    id: "infinity",
    clientName: "Infinity",
    projectType: "Infinity Naturals Launch Trip",
    title: "Infinity",
    workScope:
      "Influencer Trip Management + Public Relations and Guest List Management ",
    location: "Azha - Al Ain Al Sokhna",
    numImg: 15,
    coverImg: "/img/portfolios/InfinityNaturals/600/4.jpg",
    images: [
      {
        src: "/img/portfolios/InfinityNaturals/2000/1.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/2.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/3.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/4.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/5.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/6.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/7.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/8.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/9.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/10.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/10.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/11.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/12.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/13.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
      {
        src: "/img/portfolios/InfinityNaturals/2000/14.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },

      {
        src: "/img/portfolios/InfinityNaturals/2000/15.jpg",
        loading: "lazy",
        alt: "Infinity Naturals",
      },
    ],
    videos: [
      {
        id: "1",
        link: "https://www.youtube.com/embed/dV9Y7Dq_SKA",
      },
    ],
  },

  // // ----------------------------------- //
  // // Kiko Milano
  {
    id: "kiko-milano-launch-egypt",
    clientName: "Kiko Milano",
    title: "Kiko Milano Launch in Egypt",
    projectType: "Kiko Milano Launch in Egypt Event",
    workScope:
      "Public Relations and Guest List Management + Event Management and Production + Trendsetters Store Visists",
    location: "KIKO Milano Store at Mall of Egypt",
    numImg: 7,
    coverImg: "/img/portfolios/KikoMilano/2000/6.jpg",
    images: [
      {
        src: "/img/portfolios/KikoMilano/2000/1.jpg",
        loading: "lazy",
        alt: "Kiko Milano Launch Egypt",
      },
      {
        src: "/img/portfolios/KikoMilano/2000/2.jpg",
        loading: "lazy",
        alt: "Kiko Milano Launch Egypt",
      },
      {
        src: "/img/portfolios/KikoMilano/2000/3.jpg",
        loading: "lazy",
        alt: "Kiko Milano Launch Egypt",
      },
      {
        src: "/img/portfolios/KikoMilano/2000/4.jpg",
        loading: "lazy",
        alt: "Kiko Milano Launch Egypt",
      },
      {
        src: "/img/portfolios/KikoMilano/2000/5.jpg",
        loading: "lazy",
        alt: "Kiko Milano Launch Egypt",
      },
      {
        src: "/img/portfolios/KikoMilano/2000/6.jpg",
        loading: "lazy",
        alt: "Kiko Milano Launch Egypt",
      },
      {
        src: "/img/portfolios/KikoMilano/2000/7.jpg",
        loading: "lazy",
        alt: "Kiko Milano Launch Egypt",
      },
    ],
    videos: [
      {
        id: "1",
        link: "https://www.youtube.com/embed/IRgVEopLPBk",
      },
      {
        id: "2",
        link: "https://www.youtube.com/embed/LWonDahJGL0",
      },
      {
        id: "3",
        link: "https://www.youtube.com/embed/jyAQBVwKX7U",
      },
      {
        id: "4",
        link: "https://www.youtube.com/embed/VtKKQyDbaT4",
      },
      {
        id: "5",
        link: "https://www.youtube.com/embed/vQCiCpRnaic",
      },
    ],
  },
  // ----------------------------------- //
  // Jotun Color Workshop Event
  {
    id: "color-workshop",
    clientName: "Jotun",
    projectType: "Wonderwall Lux Launch Event",
    workScope:
      "Event Management + PR + Video Production + Guest List Management",
    location: "Villa Belle Epoque Hotel",
    title: "Color Workshop Event",
    numImg: 0,
    coverImg: "/img/portfolios/Jotun/600/1.jpg",
    images: [],
    videos: [
      {
        id: "1",
        link: "https://www.youtube.com/embed/PqOgI3BmTcI",
      },
    ],
  },
];

export const kits = [
  {
    id: "1",
    img: "./img/kits/1.png",
    title: "Clear",
    clientName: "Unilever Egypt",
    projectType: "PR Gifting Campaign",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/kits/1.png",
      },
    ],
  },

  {
    id: "2",
    img: "./img/kits/3.png",
    title: "Dettol",
    clientName: "Unilever Egypt",
    projectType: "PR Gifting Campaign",
    workScope: " Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/Dettol/1.png",
      },
      {
        src: "/./img/Dettol/2.png",
      },
    ],
  },

  {
    id: "3",
    img: "./img/kits/4.png",
    title: "Infinity Naturals",
    clientName: "Infinity Spanish Labs",
    projectType: "Influencers Trip",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/Infinity Naturals/1.png",
      },
      {
        src: "/./img/Infinity Naturals/2.png",
      },
      {
        src: "/./img/Infinity Naturals/3.png",
      },
      {
        src: "/./img/Infinity Naturals/4.png",
      },
    ],
  },

  {
    id: "4",
    img: "./img/kits/5.png",
    title: "KIKO Milano - Summer",
    clientName: "KIKO Milano",
    projectType: "PR Gifting Campaign",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/KIKO Milano - Summer/1.png",
      },
      {
        src: "/./img/KIKO Milano - Summer/2.png",
      },
      {
        src: "/./img/KIKO Milano - Summer/3.png",
      },
      {
        src: "/./img/KIKO Milano - Summer/4.png",
      },
    ],
  },

  {
    id: "5",
    img: "./img/kits/6.png",
    title: "Nivea Black & White",
    clientName: "Beiersdorf Egypt",
    projectType: "PR Gifting Campaign",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/Nivea Black & White/1.png",
      },
      {
        src: "/./img/Nivea Black & White/2.png",
      },
    ],
  },

  {
    id: "6",
    img: "./img/kits/7.png",
    title: "Nivea Men",
    clientName: "Beiersdorf Egypt",
    projectType: " PR Gifting Campaign",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/kits/7.png",
      },
    ],
  },

  {
    id: "7",
    img: "./img/kits/8.png",
    title: "Nivea Soft",
    clientName: "Beiersdorf Egypt",
    projectType: "PR Gifting Campaign",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/kits/8.png",
      },
    ],
  },

  {
    id: "8",
    img: "./img/kits/9.png",
    title: "Sunsilk Thick & Long",
    clientName: "Unilever Egypt",
    projectType: " PR Gifting Campaign",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/kits/9.png",
      },
    ],
  },

  {
    id: "9",
    img: "./img/kits/10.png",
    title: "Vaseline",
    clientName: "Unilever Egypt",
    projectType: " PR Gifting Campaign",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/kits/10.png",
      },
    ],
  },

  {
    id: "10",
    img: "./img/kits/11.png",
    title: "WHAM Clinics",
    clientName: " Wham Clinics",
    projectType: "PR Gifting Campaign",
    workScope: "Kit Design + Kit Production + Influencers Management",
    images: [
      {
        src: "/./img/WHAM Clinics/1.png",
      },
      {
        src: "/./img/WHAM Clinics/2.png",
      },
    ],
  },
];

// using google drive
// export const portfolios2 = [
//   {
//     id: "prime-developments",
//     clientName: "Prime Developments",
//     projectType: "Launch Event ",
//     workScope: "Public Relations and Guest List Management",
//     location: "JW Marriott Hotel",
//     numImg: 22,
//     images: [
//       {
//         src: "https://drive.google.com/uc?export=view&id=1nSpdtnpoYu4rzTES7sJuNpjHENQgRXDc",
//         loading: "lazy",
//         alt: "Prime Developments",
//       },
//       {
//         src: "https://drive.google.com/uc?export=view&id=1nSpdtnpoYu4rzTES7sJuNpjHENQgRXDc",
//         loading: "lazy",
//         alt: "Prime Developments",
//       },
//       {
//         src: "https://drive.google.com/uc?export=view&id=1nSpdtnpoYu4rzTES7sJuNpjHENQgRXDc",
//         loading: "lazy",
//         alt: "Prime Developments",
//       },
//       {
//         src: "https://drive.google.com/uc?export=view&id=1QnRFOcm42vaFItEbhp3jmr9SyZGbsfDk",
//         loading: "lazy",
//         alt: "Prime Developments",
//       },
//       {
//         src: "https://drive.google.com/uc?export=view&id=1QnRFOcm42vaFItEbhp3jmr9SyZGbsfDk",
//         loading: "lazy",
//         alt: "Prime Developments",
//       },

//       {
//         src: "https://drive.google.com/uc?export=view&id=1QnRFOcm42vaFItEbhp3jmr9SyZGbsfDk",
//         loading: "lazy",
//         alt: "Prime Developments",
//       },
//     ],
//     videos: [
//       {
//         id: "1",
//         link: "https://youtube.com/shorts/V_by5uvNFUg?feature=share",
//       },
//     ],
//   },
// ];

export const team = [
  {
    name: "Habeeba Hamed",
    img: "/img/Team/HabeebaHamed.png",
  },
  {
    name: "Habiba Ezzat",
    img: "/img/Team/HabibaEzzat.png",
  },
  {
    name: "Habiba Hegazy",
    img: "/img/Team/HabibaHegazy.png",
  },
  {
    name: "Hager Barakat",
    img: "/img/Team/HagerBarakat.png",
  },
  {
    name: "Hana Elziki",
    img: "/img/Team/HanaElziki.png",
  },
  {
    name: "Heidi Hany",
    img: "/img/Team/HeidiHany.png",
  },
  {
    name: "Leen Elshikh",
    img: "/img/Team/LeenElshikh.png",
  },
  {
    name: "Malak Ashmawi",
    img: "/img/Team/MalakAshmawi.png",
  },
  {
    name: "Malak Seyam",
    img: "/img/Team/MalakSeyam.png",
  },
  {
    name: "Naira Abo Elnaga",
    img: "/img/Team/NairaAboElnaga.png",
  },
  {
    name: "Noura Ahmed",
    img: "/img/Team/NouraAhmed.png",
  },
  {
    name: "Ola Eid",
    img: "/img/Team/OlaEid.png",
  },
  {
    name: "Rana Roushdy",
    img: "/img/Team/RanaRoushdy.png",
  },
];
