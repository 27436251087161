import { AiOutlineClose } from "react-icons/ai";

import style from "../style";

const Video = ({ src, cb }) => {
  return (
    <>
      <div
        className="fixed top-0 left-0 w-[100vw] h-[100vh] sliderContainer bg-overlay flex items-center justify-center z-[100]"
        // onClick={() => setOpenSlider(false)}
      >
        <div className="w-[80%] h-[80%] p-6">
          <iframe
            width="100%"
            height="100%"
            loading="lazy"
            src={src}
            title="YouTube video player"
            allowFullScreen
          />
        </div>

        <span
          className={`${style.sliderCloseBtn} ${style.sliderBtn}`}
          onClick={() => cb(false)}
        >
          <AiOutlineClose />
        </span>
      </div>
    </>
  );
};

export default Video;
