import "./sass/main.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import { useEffect } from "react";
import Portfolio from "./pages/Portfolio";
import PortfolioSix from "./pages/PortfolioSix";

function App() {
  const { hash } = useLocation();

  // console.log(location);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [hash]); // do this on route change

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/portfolio/:id" element={<Portfolio />} /> */}
      <Route path="portfolio/:id" element={<PortfolioSix />} />
    </Routes>
  );
}

export default App;
