// eslint-disable-next-line import/no-anonymous-default-export
export default {
  flexCenter: "flex items-center justify-center",
  section: "w-full max-w-[1024px] my-[150px] py-6",
  sectionTitle:
    "flex-1 uppercase text-[12px] tracking-[3px] font-bold mb-5 min-w-[150px]",
  sectionParagraph: "max-w-[700px] text-[16px] font-light",
  navLink:
    "text-[12px] font-medium text-black uppercase p-1 hover:border-gray-200 hover:border-b-2",
  btn: "text-[14px] bg-black text-white rounded-md py-2 px-4 font-semibold capitalize hover:opacity-90",

  // slider
  sliderBtn:
    "text-md text-black z-[101] absolute cursor-pointer w-8 h-8 flex items-center justify-center bg-white rounded-full hover:opacity-90",
  sliderLeftBtn: "top-1/2 left-8",
  sliderRightBtn: "top-1/2 right-9",
  sliderCloseBtn: "top-8 right-9",

  
};

