import styles from "../style";
import { portfolios } from "../constatns";
import { Link } from "react-router-dom";
import { kits } from "../constatns";




const PortfolioGrid = () => (
  <section  className={`${styles.section}`}>
    <div className="flex flex-col px-6 lg:px-0">
      <h1 className={`flex-1 ${styles.sectionTitle}`}>SAMPLE OF OUR WORK</h1>

      <span id="portfolio" className={`flex-1 ${styles.sectionTitle}`}>
      Events
      </span>

      <div className="mt-6 flex flex-wrap flex-col md:flex-row items-center justify-center gap-14 ">
        {portfolios.map((protfolio) => (
          <div
            className="relative w-full max-w-[450px] h-[400px] rounded-md flex items-center justify-center"
            key={protfolio.id}
          >
            <div className="w-full h-full rounded-md overflow-hidden">
              <img
                src={protfolio.coverImg}
                alt={protfolio.alt}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex-1 absolute -bottom-8 flex flex-col items-center justify-center gap-2 p-2 bg-white w-9/12 rounded-md shadow-md">
              <h4 className="font-medium text-[16px]">{protfolio.title}</h4>

              <Link
                to={`portfolio/${protfolio.id}`}
                className={`${styles.btn}`}
              >
                Read more
              </Link>
            </div>
          </div>
        ))}





      </div>
    </div>









    <div className="flex flex-col px-6 lg:px-0 mt-20">
    
      <span id="Kits" className={`flex-1 ${styles.sectionTitle}`}>
    PR Kits
      </span>



      <div className="mt-6 flex flex-wrap flex-col md:flex-row items-center justify-center gap-14 ">
      {kits.map((kit) => (
          <div
            className="relative w-full max-w-[450px] h-[400px] rounded-md flex items-center justify-center"
            key={kit.id}
          >
            <div className="w-full h-full rounded-md overflow-hidden">
              <img
                src={kit.img}
                alt="img"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex-1 absolute p-5 -bottom-8 flex flex-col items-center justify-center gap-2 p-2 bg-white w-9/12 rounded-md shadow-md">
              <h4 className="font-medium text-[16px]">{kit.title}</h4>
              <Link
                to={`portfolio/${kit.id}`}
                className={`${styles.btn}`}
              >
                Read more
              </Link>
            </div>
          </div>
        ))}

      </div>
    </div>




















  </section>
);

export default PortfolioGrid;
