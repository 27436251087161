import styles from "../style";
import { stats } from "./../constatns";

const WhoAreWe = () => (
  <section id="whoarewe" className={`${styles.section}`}>
    <div className="flex flex-col items-start justify-between lg:px-0">
      <div className="flex-1 w-full flex flex-col md:flex-row items-start justify-between px-6 lg:px-0">
        <h1 className={`flex-1 ${styles.sectionTitle}`}>who we are</h1>
        <div className="flex flex-col gap-8">
          <p className={`${styles.sectionParagraph}`}>
            We believe that great project development comes from the best
            collaboration! That’s why we like to work closely with our clients
            to get under the skin of the brand - it's essential for us to
            understand the brand's DNA in order to deliver bespoke ideas
          </p>

          <p className={`${styles.sectionParagraph}`}>
            Our ideas are creative, we use a mix of both strategic and tactical
            methods, which make an impact on our client’s brands, targeting
            their desired client base through the right tools.
          </p>

          <p className={`${styles.sectionParagraph}`}>
            We are specialists in experiential and will build a project from the
            ground up; designing the look and consumer journey, sourcing venues
            and locations, working with local councils to secure permits,
            creating a bespoke team of brand ambassadors, constructing sets, and
            delivering the perfect product.
          </p>
        </div>
      </div>

      {/* TODO: stats */}
      <div className="mt-14 flex flex-wrap justify-center gap-4 cursor-pointer">
        {stats.map((s, index) => (
          <div
            className="stats flex flex-col items-center justify-start p-5 rounded-md bg-gray-200 w-[200px] hover:bg-black hover:text-white shadow"
            key={s.id}
          >
            <span className="text-[45px] font-extrabold">{s.num}</span>
            <span className="capitalize text-[16px] font-light text-center">
              {s.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default WhoAreWe;
