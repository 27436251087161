import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { useParams } from "react-router-dom";

// import required modules
import { Lazy, Pagination, Navigation, Autoplay } from "swiper";
import styles from "../style";
import { portfolios } from "../constatns";

const Portfolio = () => {
  const { id } = useParams() || "";
  const [data, setData] = useState(null);

  useEffect(() => {
    if (id !== "" && !data) {
      setData(portfolios.filter((p) => p.id === id)[0]);
    }
  }, [id, data]);

  if (!data) {
    return <p>loading ... </p>;
  }

  return (
    <main className="w-full flex flex-col items-center justify-center">
      <div
        className={`${styles.section}  flex flex-col-reverse lg:flex-row p-4`}
      >
        <div className="flex-1 flex flex-col mt-8 lg:mt-0 mr-4">
          <h1 className={`${styles.sectionTitle} grow-0 mb-4`}>
            {data.clientName}
          </h1>

          <div className="flex flex-col gap-2">
            <p className={`${styles.sectionParagraph}`}>
              <span className="font-medium mr-2 uppercase text-sm">Type:</span>
              {data.projectType}
            </p>

            <p className={`${styles.sectionParagraph}`}>
              <span className="font-medium mr-2 uppercase text-sm">scope:</span>
              {data.workScope}
            </p>

            <p className={`${styles.sectionParagraph}`}>
              <span className="font-medium mr-2 uppercase text-sm">
                location:
              </span>
              {data.location}
            </p>
          </div>
        </div>

        {/* slider */}
        <div className="flex-1 bg-stone-900">
          <Swiper
            pagination={{
              type: "fraction",
            }}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            lazy={true}
            centeredSlides={true}
            navigation={true}
            modules={[Lazy, Autoplay, Navigation]}
            className="min-w-[300px] max-w-[600px] h-[400px]"
          >
            {data.images.map((img) => (
              <SwiperSlide>
                <img
                  src={img.src}
                  alt={img.alt}
                  className="w-full h-full object-cover swiper-lazy"
                />
              </SwiperSlide>
            ))}

            {/* <SwiperSlide>
              <iframe
                title="unique id"
                className="w-full h-full"
                src="https://www.youtube.com/embed/tgbNymZ7vqY"
              ></iframe>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </main>
  );
};

export default Portfolio;
