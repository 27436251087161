import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import Scrollup from "./Scrollup";

function Layout({ children }) {
  return (
    <div className="w-full flex flex-col overflow-hidden bg-slate-50">
      <Navbar />
      <Scrollup />
      {children}
      <Footer />
    </div>
  );
}

export default Layout;
