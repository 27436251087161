import styles from "../style";
import { team } from "../constatns";

const teamStyle = {
  width: "200px",
  height: "200px",
  backgroundPosition: "center",
  backgroundSize: "cover",
};

function Team() {
  return (
    <section id="team" className={`${styles.section}`}>
      <div className="flex flex-col px-6 lg:px-0">
        <h1 className={`flex-1 ${styles.sectionTitle}`}>The Founder</h1>

        <div className="flex flex-col md:flex-row">
          <div className="w-[280px] h-[280px] mr-10 mb-5 overflow-hidden rounded-sm">
            <img
              src="/img/Team/Dina Dash.png"
              alt="dina dash"
              className="object-cover w-full h-full grayscale"
            />
          </div>
          <div className="flex flex-col gap-6">
            <h2 className="text-[14px] uppercase font-bold">Dina dash</h2>
            <p className={`${styles.sectionParagraph}`}>
              Dina founded DASH MANAGEMENT in 2015 guided by her entrepreneurial
              spirit and passion for Marketing. She entered the industry at a
              very young age and paved her way through with her wit and
              creativity.
            </p>

            <p className={`${styles.sectionParagraph}`}>
              She built a team of young talented individuals and together they
              continued to build and grow the agency into the one of the leading
              Digital Marketing and PR agencies in town.
            </p>

            <p className={`${styles.sectionParagraph}`}>
              DASH MANAGEMENT won over 100+ clients in different industries like
              fashion, beauty, lifestyle, F&B, & hospitality in only 7 years of
              being in the market. DASH MANAGEMENT is now well known for its
              reputation and extensive network with the media, influencers, &
              celebrities which continues to aid the growing portfolio of
              clients.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-32 flex flex-col px-6 lg:px-0">
        <h1 className={`flex-1 ${styles.sectionTitle} mb-10`}>Team</h1>

        {/* TEAM CONTAINER */}
        {/* <div className="w-full flex flex-wrap justify-center gap-3">
          {team.map((person) => (
            <div
              className=""
              key={person.name}
              style={{ background: `url(${person.img})`, ...teamStyle }}
            >
              <div className="opacity-0 hover:opacity-100 bg-overlay flex w-full h-full items-center justify-center cursor-pointer relative">
                <span className="text-white text-lg capitalize font-semibold">
                  {person.name}
                </span>
              </div>
            </div>
          ))}
        </div> */}

        <div className="w-full flex flex-wrap justify-center gap-3">
          {team.map((person) => (
            <div className="h-[250px] text-center" key={person.name}>
              <div
                className=""
                style={{ background: `url(${person.img})`, ...teamStyle }}
              ></div>
              <span className="text-black text-lg capitalize font-semibold">
                {person.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;
