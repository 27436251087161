import { footerLinks } from "../../../constatns";
import "./footer.scss";

const footer = () => {
  const footerItem = (link) => {
    if (link.id === "mail")
      return <a href={`mailto:${link.value}`}>{link.label}</a>;
    else if (link.id === "tel")
      return <a href={`tel:${link.value}`}>{link.label}</a>;
    else return <a href={`${link.value}`}>{link.label}</a>;
  };

  return (
    <div className="w-full min-h-[30vh] flex flex-col items-center px-[5rem] pt-[2rem]">
      <div className="w-full max-w-[1024px] flex flex-col items-center justify-between">
        {footerLinks.map((list) => (
          <div className="" key={list.title}>
            <h3 className="tracking-widest font-bold  text-[13px] text-center mb-4">
              {list.title}
            </h3>
            <ul className="w-100 flex flex-col items-center justify-between gap-2">
              {list.links.map((link) => (
                <li
                  key={link.id}
                  className="footer__item font-thin text-[16px] cursor-pointer"
                >
                  {footerItem(link)}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <span className="mt-3 text-[3rem] md:text-[5rem] text-center text-black opacity-[0.9]">
        DASH MANAGEMENT
      </span>
    </div>
  );
};

export default footer;
