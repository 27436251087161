import React, { useState, useEffect } from "react";

const Scrollup = () => {
  const [isVisiable, setIsVisisble] = useState(false);

  const toggleVisiable = () => {
    const scrolled = document.documentElement.scrollTop;
    scrolled > 300 ? setIsVisisble(true) : setIsVisisble(false);
  };

  window.addEventListener("scroll", toggleVisiable);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`${
        isVisiable ? "block" : "hidden"
      } fixed w-[50px] h-[50px] text-[14px] right-8 bottom-8 z-10 shadow bg-black text-white p-2 rounded-full cursor-pointer -rotate-90 hover:-translate-y-1 hover:shadow-md`}
    >
      &#10140;
    </button>
  );
};

export default Scrollup;
