import styles from "../style";

const Clients = () => (
  <section id="clients" className={`${styles.section}`}>
    <div className="flex flex-col px-6 lg:px-0">
      <div className="relative mb-2 ">
      <h1 className={`flex-1 mb-5 ${styles.sectionTitle}  clientTitle`}>
          CLIENTS
        </h1>
        <h1 className={`flex-1 ${styles.sectionTitle}  clientTitle`}>
          HOSPITALITY <br /> FOOD & BEVERAGE
        </h1>
      </div>
      <div className="flex flex-row  flex-wrap gap-2 overflow-hidden justify-center">
        {Array.from(Array(24).keys()).map((_, index) => (
          <img
            key={`Client-${index + 1}`}
            src={`/img/food/${index + 1}.png`}
            className="w-[150px] h-[150px] object-contain"
            alt={`Client`}
          />
        ))}
      </div>
    </div>

    <div className="flex flex-col px-6  mt-16 lg:px-0">
      <div className="relative mb-2  ">
        <h1 className={`flex-1  ${styles.sectionTitle}   clientTitle`}>
          BEAUTY{" "}
        </h1>
      </div>
      <div className="flex flex-row   flex-wrap gap-2 overflow-hidden justify-center">
        {Array.from(Array(44).keys()).map((_, index) => (
          <img
            key={`Client-${index + 1}`}
            src={`/img/beauty/${index + 1}.png`}
            className="w-[150px] h-[150px] object-contain"
            alt={`Client`}
          />
        ))}
      </div>
    </div>

    
    <div className="flex flex-col px-6  mt-16 lg:px-0">
      <div className="relative mb-2  ">
        <h1 className={`flex-1  ${styles.sectionTitle}  uppercase clientTitle`}>
        convenience{" "}
        </h1>
      </div>
      <div className="flex flex-row   flex-wrap gap-2 overflow-hidden justify-center">
        {Array.from(Array(17).keys()).map((_, index) => (
          <img
            key={`Client-${index + 1}`}
            src={`/img/convenience/${index + 1}.png`}
            className="w-[150px] h-[150px] object-contain"
            alt={`Client`}
          />
        ))}
      </div>
    </div>
  </section>
);

export default Clients;
