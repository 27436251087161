import React, { useEffect, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import styles from "../style";
import { portfolios } from "../constatns";
import Slider from "../components/Slider";
import style from "../style";
import Video from "../components/Video";
import { kits } from "../constatns";

const PortfolioSix = () => {
  const { id } = useParams() || "";
  const [data, setData] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [openSlider, setOpenSlider] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [vIndex, setVIndex] = useState(-1);
  const[kit , setKit] = useState(null)
  const handleClickSlideImg = (index) => {
    return () => {
      setSlideIndex(index);
      !openSlider && setOpenSlider(true);
    };
  };
  const handleOpenVideo = (index) => {
    setOpenVideo(false);
    setVIndex(index);
    setOpenVideo(true);
  };
  useEffect(() => {
    if (id !== "" && !data || !kit ) {
      setData(portfolios.filter((p) => p.id === id)[0]);
      setKit(kits[id -1])
    }
  }, [id, data , kit]);

  if (!data && !kit) {
    return <p>loading ... </p>;
  }

  return (
<>
  {data?
  <>
        <main className="w-full flex flex-col items-center justify-center">
      <div
        className={`${styles.section} flex flex-col-reverse lg:flex-row p-4`}
      >
        <div className="flex-1 flex flex-col mt-8 lg:mt-0 mr-4">
          <h1 className={`${styles.sectionParagraph} grow-0 mb-2`}>
            <span className="font-medium mr-2 uppercase text-sm">
              Client Name:
            </span>
            {data.clientName}
          </h1>

          <div className="flex flex-col gap-2">
            <p className={`${styles.sectionParagraph}`}>
              <span className="font-medium mr-2 uppercase text-sm">
                Project Type:
              </span>
              {data.projectType}
            </p>

            <p className={`${styles.sectionParagraph}`}>
              <span className="font-medium mr-2 uppercase text-sm">
                scope of work:
              </span>
              {data.workScope}
            </p>

            <p className={`${styles.sectionParagraph}`}>
              <span className="font-medium mr-2 uppercase text-sm">
                location:
              </span>
              {data.location}
            </p>
          </div>
        </div>

        {/* main img */}
        {
          data.images.length > 0 && (

            <div className="hidden lg:flex flex-wrap max-w-5xl rounded-2xl overflow-hidden w-[400px]">
              <img
                src={data.images[0].src}
                alt={data.images[0].alt}
                className="w-full h-full object-cover swiper-lazy"
              />
            </div>
          )
        }
      </div>

      {/* PHOTOS */}
      {data.images.length > 0 && (
        <div className="flex flex-col items-center">
          <h5 className={`${style.sectionTitle}`}>Photos</h5>
          <div className="mb-14 w-full max-w-[1200px] px-6 flex flex-wrap justify-center gap-2 ">
            {data.images.map((img, index) => (
              <div
                key={index}
                className="w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] rounded-lg overflow-hidden cursor-pointer hover:opacity-80"
                onClick={handleClickSlideImg(index)}
              >
                <img
                  src={img.src}
                  alt={img.alt}
                  className="w-full h-full object-cover swiper-lazy"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Videos */}
      {data.videos.length > 0 && (
        <div className="flex flex-col justify-center">
          <h5 className={`${style.sectionTitle} text-center`}>videos</h5>

          <div className="flex flex-row flex-wrap justify-center gap-3">
            {data.videos.map((v, index) => (
              <div
                key={`Video-${index}`}
                className="w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] bg-overlay flex items-center justify-center rounded-xl"
              >
                <AiFillPlayCircle
                  fill="#fff"
                  className=" cursor-pointer text-6xl"
                  onClick={() => handleOpenVideo(index)}
                />

                {openVideo && vIndex === index && (
                  <Video src={v.link} cb={setOpenVideo} />
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* slider */}
      {openSlider && (
        <Slider
          slides={data.images}
          startIndex={slideIndex}
          cb={setOpenSlider}
        />
      )}
      {
        
      }
    </main>
  </> :""
}


{
  kit!= undefined?     
  <main className="w-full flex flex-col items-center justify-center">
  <div
    className={`${styles.section} flex flex-col-reverse lg:flex-row p-4`}
  >
    <div className="flex-1 flex flex-col mt-8 lg:mt-0 mr-4">
      <h1 className={`${styles.sectionParagraph} grow-0 mb-2`}>
        <span className="font-medium mr-2 uppercase text-sm">
          Client Name:
        </span>
        {kit.clientName}
      </h1>

      <div className="flex flex-col gap-2">
        <p className={`${styles.sectionParagraph}`}>
          <span className="font-medium mr-2 uppercase text-sm">
            Project Type:
          </span>
          {kit.projectType}
        </p>

        <p className={`${styles.sectionParagraph}`}>
          <span className="font-medium mr-2 uppercase text-sm">
            scope of work:
          </span>
          {kit.workScope}
        </p>
      </div>
    </div>

    {/* main img */}
    {
      kit.images.length > 0 && (

        <div className="hidden lg:flex flex-wrap max-w-5xl rounded-2xl overflow-hidden w-[400px]">
          <img
            src={kit.images[0].src}
            alt={"img"}
            className="w-full h-full object-cover swiper-lazy"
          />
        </div>
      )
    }
  </div>

  {/* PHOTOS */}
  {kit.images.length > 0 && (
    <div className="flex flex-col items-center">
      <h5 className={`${style.sectionTitle}`}>Photos</h5>
      <div className="mb-14 w-full max-w-[1200px] px-6 flex flex-wrap justify-center gap-2 ">
        {kit.images.map((img, index) => (
          <div
            key={index}
            className="w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] rounded-lg overflow-hidden cursor-pointer hover:opacity-80"
            onClick={handleClickSlideImg(index)}
          >
            <img
              src={img.src}
              alt={"img"}
              className="w-full h-full object-cover swiper-lazy"
            />
          </div>
        ))}
      </div>
    </div>
  )}

  {/* Videos */}
  {/* {data.videos.length > 0 && (
    <div className="flex flex-col justify-center">
      <h5 className={`${style.sectionTitle} text-center`}>videos</h5>

      <div className="flex flex-row flex-wrap justify-center gap-3">
        {data.videos.map((v, index) => (
          <div
            key={`Video-${index}`}
            className="w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] bg-overlay flex items-center justify-center rounded-xl"
          >
            <AiFillPlayCircle
              fill="#fff"
              className=" cursor-pointer text-6xl"
              onClick={() => handleOpenVideo(index)}
            />

            {openVideo && vIndex === index && (
              <Video src={v.link} cb={setOpenVideo} />
            )}
          </div>
        ))}
      </div>
    </div>
  )} */}

  {/* slider */}
  {/* {openSlider && (
    <Slider
      slides={data.images}
      startIndex={slideIndex}
      cb={setOpenSlider}
    />
  )}
  {
    
  } */}
</main>
:""
}
</>
  );
};

export default PortfolioSix;
