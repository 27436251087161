import React from "react";
import About from "../components/About";
import Career from "../components/Career";
import Clients from "../components/Clients";
import PortfolioGrid from "../components/PortfolioGrid";
import Services from "../components/Services";
import Team from "../components/Team";
import WhoAreWe from "../components/WhoAreWe";

function Home() {
  return (
    <main className="bg-slate-50 w-full flex flex-col items-center justify-center">
      <About />
      <WhoAreWe />
      <Team />
      <Services />
      <PortfolioGrid />
      <Clients />
      <Career />
    </main>
  );
}

export default Home;
