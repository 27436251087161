import styles from "../style";

const About = () => (
  <section id="about" className={`${styles.section}`}>
    <div className="flex flex-col md:flex-row items-start justify-between px-6 lg:px-0">
      <h1 className={`flex-1 ${styles.sectionTitle}`}>about us</h1>

      <div className="flex flex-col gap-8">
        <p className={`${styles.sectionParagraph}`}>
          We are an ideas driven, design-led creative luxury digital media &
          event management marketing agency.
        </p>

        <p className={`${styles.sectionParagraph}`}>
          We are a team of young talented individuals bringing a fresh eye into
          the market since 2015. We believe in being first movers with creative
          and trendy marketing. From DASH’s experiences, we have been able to
          help market all kinds of events, brands, corporations to reach your
          desired target audience immediately and effective.
        </p>
      </div>
    </div>
  </section>
);

export default About;
