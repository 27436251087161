import styles from "../style";

const Career = () => (
  <section id="career" className={`${styles.section}`}>
    <div className="flex flex-col md:flex-row items-start justify-between px-6 lg:px-0">
      <h1 className={`flex-1 ${styles.sectionTitle}`}>Careers</h1>

      <div className="flex flex-col gap-8">
        <p className={`${styles.sectionParagraph}`}>
          DASH Management actively recruits talented women who want more than
          just a job, but those who strive to make a difference. If that sounds
          like you, tell us about yourself by sending an email to:
          info@dashmanagement.me
        </p>
      </div>
    </div>
  </section>
);

export default Career;
