import { useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import style from "../style";

const Slider = ({ slides, startIndex, cb }) => {
  const [slideIndex, setSlideIndex] = useState(startIndex || 0);
  //   const [openSlider, setOpenSlider] = useState(true);

  const changeSlideIndex = (index, direction) => {
    return () => {
      if (direction === "left") {
        const isFirstSlide = index === 0;
        setSlideIndex(isFirstSlide ? slides.length - 1 : index - 1);
      } else {
        const isLastSlide = index === slides.length - 1;
        setSlideIndex(isLastSlide ? 0 : index + 1);
      }
    };
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-[100vw] h-[100vh] sliderContainer bg-overlay flex items-center justify-center z-[100]"
        // onClick={() => cb(false)}
      >
        <div className="slide flex items-center justify-center w-full lg:max-w-[1200px] lg:h-full p-9">
          <img
            src={slides[slideIndex].src}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>

        <span
          className={`${style.sliderLeftBtn} ${style.sliderBtn}`}
          onClick={changeSlideIndex(slideIndex, "left")}
        >
          <AiOutlineLeft />
        </span>
        <span
          className={`${style.sliderRightBtn} ${style.sliderBtn}`}
          onClick={changeSlideIndex(slideIndex, "right")}
        >
          <AiOutlineRight />
        </span>

        <span
          className={`${style.sliderCloseBtn} ${style.sliderBtn}`}
          onClick={() => cb(false)}
        >
          <AiOutlineClose />
        </span>
      </div>
    </>
  );
};

export default Slider;
